<template>
  <div>
    <skeleton theme="opacity" shape="radius" bg-color="#dcdbdc">
    <two-cards sm="12" md="3" lg="3" xl="3">
      <template #header>
        <PageTitle title="Tutoriais"/>
      </template>
      <template #one>
        <div class="card invision-card elevate-2 borderless">
          <span class="ml-3 mt-3 text-caption2">CATEGORIAS</span>
          <b-list-group flush>
            <b-list-group-item
              v-for="item in [1,2,3]"
              :key="item"
              class="item padding">
              <b-row>
                <b-col align-self="start">
                  <tb-skeleton width="80%" height="50px" ></tb-skeleton>
                </b-col>
              </b-row>
            </b-list-group-item>
          </b-list-group>
        </div>
      </template>
      <template #two>
        <tb-skeleton width="30%" height="16px" class="mb-1"></tb-skeleton>
        <div>
          <b-row
            class="white"
            v-for="tutorialEmpty in [ 1,2,3,4,5]"
            :key="tutorialEmpty"
            >
            <b-col sm="4" md="4" lg="3" xl="3">
              <tb-skeleton class="thumbnail" />
            </b-col>
            <b-col>
              <b-row class="mb-2">
                <b-col>
                  <tb-skeleton width="20%" height="24px"  class="text-caption2"></tb-skeleton>
                </b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col>
                  <tb-skeleton width="70%" height="24px" class="text-caption2"></tb-skeleton>
                </b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col>
                 <tb-skeleton width="90%" height="24px" class="text-caption2"></tb-skeleton>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </template>
    </two-cards>
    </skeleton>
  </div>
</template>

<script>
import TwoCards from '@/templates/TwoCards';
import PageTitle from '@/templates/PageTitle';

export default {
  components: {
    TwoCards,
    PageTitle,
  },
};
</script>

<style lang="scss" scoped>
$neutral-color-10: #FFFFFF;
$neutral-color-60: #7E829F;
$neutral-color-70: #5E627A;
$neutral-color-80: #35384D;

h5 {
  line-height: 140%;
  font-weight: 500;
  font-size: 18px;
  color: $neutral-color-80;
}

.white {
  background-color: white;
  padding: 15px;
  max-width: 750px;
  margin-bottom: 10px;
}

.thumbnail {
  height: 100px;
  width: 155px;
  border-radius: 5px;
}

.toast-qrcode {
  position: absolute;
  top: -30px;
  background-color: rgba(126, 130, 159, 0.8);
  color: white;
  padding: 5px 8px;
  border-radius: 5px;
  font-size: 12px;
}

.d-none {
  display: none;
}

.d-block {
  display: block;
}

.custom-button {
  border-radius: 0px 7px 7px 0px !important;
}

.col-qr-code{
  text-align: justify;
  background-color: #F0F1FC;
  border-radius: 5px 0px 0px 5px;
  padding: 10px 16px;
  cursor: pointer;
  height: 40px;
}

.col-qr-code h4 {
  margin: 0;
  max-width: 85%;

}

.text-qr-code {
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  width: 15rem;
  display:inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* identical to box height */
  /* Neutral / neutral-color-60 */
  color: #7E829F;
}

.icon-copy {
  color: #7E829F;
}

.ml-12 {
  margin-left: 12px;
}

.number-circle {
    border-radius: 50%;
    width: 27px;
    height: 27px;
    background: $neutral-color-10;
    border: 2px solid $neutral-color-80;
    padding: 1px;
    color: $neutral-color-80;
    text-align: center;
    display: inline-block;
}

.number-circle-selected {
  background: $neutral-color-80 !important;
  color: $neutral-color-10 !important;
}

.list-group-item {
  border: 0 !important;
}

</style>
